import { makeRequest } from './make-request';
import { fetchWithPromise } from './fetch-with-cache';
import { PhoneValidator } from '../utils';

export async function sendSmsOutbound(body) {
  const { data, errorMessage } = await makeRequest('post', 'outbound-sms/send', body);
  return data ? { data } : { errorMessage };
}

export async function sendEmailOutbound(body) {
  const { data, errorMessage } = await makeRequest('post', 'outbound-email/send', body);
  return data ? { data } : { errorMessage };
}

export async function sendTestMms(crmSettingsId, phone, message, mediaUrl, podiumLocationPhone, campaign) {
  const body = {
    crmSettingsId,
    phone,
    message,
    mediaUrl,
    podiumLocationPhone,
    campaign
  };
  const { data, errorMessage } = await makeRequest('post', 'outbound-sms/send-test', body);
  return data ? { data } : { errorMessage };
}

export async function sendSingleSms(crmSettingsId, phone, message) {
  const body = {
    crmSettingsId,
    phone,
    message
  };
  const { data, errorMessage } = await makeRequest('post', 'outbound-sms/send-single', body);
  return data ? { data } : { errorMessage };
}

export async function sendTestEmail(crmSettingsId, email, templateId) {
  const body = {
    crmSettingsId,
    email,
    templateId
  };
  const { data, errorMessage } = await makeRequest('post', 'outbound-email/send-test', body);
  return data ? { data } : { errorMessage };
}

export async function getOutboundSmsRequests(limit, crmSettingsId, page = 0) {
  const key = `get-outbound-sms-requests-${limit},${page},${crmSettingsId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'outbound-sms/requests', {
    limit,
    page,
    crmSettingsId
  });
  return data ? { records: data.records, totalCount: data.totalCount } : { errorMessage };
}

export async function getOutboundEmailRequests(limit, crmSettingsId, page = 0) {
  const key = `get-outbound-email-requests-${limit},${page},${crmSettingsId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'outbound-email/requests', {
    limit,
    page,
    crmSettingsId
  });
  return data ? { records: data.records, totalCount: data.totalCount } : { errorMessage };
}

export async function aiGenerateSms(body) {
  const { data, errorMessage } = await makeRequest('post', 'sms-ai/generate', body);
  return data ? { data } : { errorMessage };
}

export async function aiGenerateSmsNextChat(chatHistory, message) {
  chatHistory = (chatHistory || []).slice(-50);
  const { data, errorMessage } = await makeRequest('post', 'sms-ai/suggest-response', { chatHistory, message });
  return data ? { data } : { errorMessage };
}

export async function aiGenerateSmsNewProspect(body) {
  const path = 'sms-ai/new-prospect';
  const { data, errorMessage } = await makeRequest('post', path, body);
  return data ? { data } : { errorMessage };
}

export async function aiGenerateSmsNewProspectAfterHours(body) {
  const path = 'sms-ai/new-prospect-after-hours';
  const { data, errorMessage } = await makeRequest('post', path, body);
  return data ? { data } : { errorMessage };
}

export async function aiGenerateSmsNewAppt(body) {
  const path = 'sms-ai/new-appt';
  const { data, errorMessage } = await makeRequest('post', path, body);
  return data ? { data } : { errorMessage };
}

export async function aiGenerateSmsNewSold(body) {
  const path = 'sms-ai/new-sold';
  const { data, errorMessage } = await makeRequest('post', path, body);
  return data ? { data } : { errorMessage };
}

export async function aiGenerateSmsReminder1(body) {
  const path = 'sms-ai/appt-reminder-1';
  const { data, errorMessage } = await makeRequest('post', path, body);
  return data ? { data } : { errorMessage };
}

export async function aiGenerateSmsReminder24(body) {
  const path = 'sms-ai/appt-reminder-24';
  const { data, errorMessage } = await makeRequest('post', path, body);
  return data ? { data } : { errorMessage };
}

export async function aiGenerateSmsReminder48(body) {
  const path = 'sms-ai/appt-reminder-48';
  const { data, errorMessage } = await makeRequest('post', path, body);
  return data ? { data } : { errorMessage };
}

export async function cancelOutboundSms(configId) {
  const body = { settingId: configId };
  const { data, errorMessage } = await makeRequest('put', 'outbound-sms/cancel', body);
  return data ? { data } : { errorMessage };
}

export async function cancelOutboundEmail(configId) {
  const body = { settingId: configId };
  const { data, errorMessage } = await makeRequest('put', 'outbound-email/cancel', body);
  return data ? { data } : { errorMessage };
}

export async function getSmsAudit(limit, orgId, page = 0, viewType, phone, outboundStart, outboundEnd) {
  phone = PhoneValidator.isValid(phone) ? PhoneValidator.normalize(phone) : '';
  const key = `get-sms-audit-${limit},${page},${orgId},${viewType},${phone},${outboundStart},${outboundEnd}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'sms-audit/find', {
    limit,
    page,
    orgId,
    viewType,
    phone,
    outboundStart,
    outboundEnd
  });
  return data ? { records: data.rows, totalCount: data.totalCount } : { errorMessage };
}

export async function getSmsActiveCondensed(orgId, phone, showLead, showAI, showOutbound, page = 0, limit = 20) {
  phone = PhoneValidator.isValid(phone) ? PhoneValidator.normalize(phone) : '';
  const key = `get-sms-active-cond-${limit},${page},${orgId},${phone}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'analytics/sms-active', {
    limit,
    page,
    orgId,
    phone,
    showLead,
    showAI,
    showOutbound
  });
  return data ? { records: data.records, totalCount: data.totalCount } : { errorMessage };
}

export async function aiGenerateOutboundCallApptBooking(body) {
  const path = 'sms-ai/outbound-call-appt-booking';
  const { data, errorMessage } = await makeRequest('post', path, body);
  return data ? { data } : { errorMessage };
}
